import React, { useState } from 'react';
import nerd from './nerd.png';
import 'bulma/css/bulma.min.css';
import './styles/App.scss';
import { tools } from "./tools";
import { projects } from "./projects";
import projectTemplate from "./projectTemplate";
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion";
import {
    drinkAnims,
    titleAnims,
    projectAnims,
    modalAnims,
    listAnims,
    listItemAnims
} from "./anims";
import { useInView } from 'react-intersection-observer';

export const imagePath = (name) => {
    return "url('" + process.env.PUBLIC_URL + '/images/' + name + ".png')"
};

function App() {
    const [projectIndex, setProjectIndex] = useState(null)
    const [showSkills, setShowSkills] = useState(false)
    const [showProjects, setShowProjects] = useState(false)
    const [command, setCommand] = useState('')
    const [error, setError] = useState(null)

    const handleChange = (event) => {
        setCommand(event.target.value)
    }

    const handleSubmit = () => {
        if (command === 'skills') {
            setShowSkills(true)
            setShowProjects(false)
            setError(null)
        } else if (command === 'projects') {
            setShowSkills(false)
            setShowProjects(true)
            setError(null)
        } else {
            setError('Invalid command, try: skills or projects')
            resetShow()
        }

        setCommand('')
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit()
        }
    }

    const resetShow = () => {
        setShowSkills(false)
        setShowProjects(false)
    }

    const [ref, inView] = useInView({
        threshold: 0,
        triggerOnce: true
    });

    const toolRender = (tool, index) => {
        return (
            <motion.div
                className="column is-half"
                key={index}
                variants={listItemAnims}
            >
                <div className='content tools'>
                    <p>{tool.title}</p>
                    <ul className='sub-tools'>
                        {tool.related.map((rel, index) => <li key={index}>{rel}</li>)}
                    </ul>
                </div>
            </motion.div>
        );
    };

    const openProject = (index) => {
        setProjectIndex(index);
    };

    const projectRender = (project, index) => {
        let image = project.image;
        let styles = { backgroundImage: imagePath(image) };

        if (project.img_pos) { styles.backgroundPosition = project.img_pos }

        return (
            <motion.div
                key={index}
                className="filter-grid-item box"
                onClick={() => openProject(index)}
                variants={listItemAnims}
                layoutId={"project_" + index}
            >
                <div className="display-face">
                    <div className="content">
                        <div className="title" style={styles} />
                    </div>
                </div>
            </motion.div>
        );
    };

    const renderProjectTemplate = (proj, index) => {
        return (
            <AnimatePresence key={index}>
                {projectIndex === index &&
                    <motion.div
                        className="project-modal-overlay"
                        onClick={() => setProjectIndex(null)}
                        variants={modalAnims}
                        animate="in"
                        initial="out"
                        exit="out"
                    >
                        <motion.div
                            className="project-modal box hacker_theme"
                            variants={projectAnims}
                            initial="out"
                            animate="in"
                            layoutId={"project_" + index}
                        >
                            {projectTemplate(proj)}
                        </motion.div>
                    </motion.div>
                }
            </AnimatePresence>
        );
    };

    return (
        <>
            <section className='hero is-fullheight' ref={ref}>
                {inView &&
                    <div className='hero-body'>
                        <div className='container'>
                            <div className="columns is-vcentered">
                                <div className="column is-one-third">
                                    <div id={'drink-holder'}>
                                        <motion.img
                                            id={'nerd-image'}
                                            src={nerd}
                                            alt="Chrigglesby"
                                            variants={drinkAnims}
                                            initial="out"
                                            animate="in"
                                        />
                                    </div>
                                </div>
                                <div className="column is-half">
                                    <div className='content'>
                                        <motion.div
                                            variants={titleAnims}
                                            initial="out"
                                            animate="in"
                                        >
                                            <input
                                                className="input"
                                                type="text"
                                                placeholder="type: skills or projects"
                                                value={command}
                                                onChange={handleChange}
                                                onKeyDown={handleKeyDown}
                                            />
                                            <AnimatePresence>
                                                {error &&
                                                    <motion.p
                                                        className="help is-danger"
                                                        initial={{ x: -25 }}
                                                        animate={{ x: 5 }}
                                                        transition={{
                                                            type: "spring",
                                                            stiffness: 260,
                                                            damping: 20
                                                        }}
                                                    >
                                                        {error}
                                                    </motion.p>
                                                }
                                            </AnimatePresence>
                                        </motion.div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {showSkills &&
                    <motion.div
                        className='box skills'
                        variants={listAnims}
                        initial="out"
                        animate="in"
                    >
                        <div className="columns is-multiline">
                            {tools.map(toolRender)}
                        </div>
                    </motion.div>
                }

                {showProjects &&
                    <AnimateSharedLayout type="crossfade">
                        <div id="filter-grid-scroll-wrapper">
                            <AnimatePresence>

                                <motion.div
                                    id="filter-grid"
                                    variants={listAnims}
                                    initial="out"
                                    animate="in"
                                >
                                    {projects.map(projectRender)}
                                </motion.div>

                            </AnimatePresence>
                        </div>
                        {projects.map(renderProjectTemplate)}
                    </AnimateSharedLayout>
                }
            </section>
        </>
    );
}

export default App;
