export const drinkAnims = {
    out: {
        x: -1000,
        rotate: 0,
    },
    in: {
        x: 0,
        rotate: 0,
        transition: {
            duration: 0.5,
            type: 'spring',
            stiffness: 200,
            bounce: 0,
            damping: 50,
            mass: 6
        }
    }
};

export const titleAnims = {
    out: {
        x: 100,
        y: 60,
        opacity: 0
    },
    in: {
        x: 0,
        y: [60, 60, 0],
        opacity: 1,
        transition: {
            delay: 1
        }
    }
};

export const projectAnims = {
    out: {
        transition: {
            duration: 0.5
        }
    },
    in: {
        transition: {
            duration: 0.5
        }
    }
};

export const modalAnims = {
    out: {
        opacity: 0,
        transition: {
            duration: 0.5
        }
    },
    in: {
        opacity: 1,
        transition: {
            duration: 0.5
        }
    }
};

export const listAnims = {
    out: {
        opacity: 0
    },
    in: {
        opacity: 1,
        transition: {
            staggerChildren: 0.05
        }
    }
};

export const listItemAnims = {
    out: {
        x: 100,
        opacity: 0
    },
    in: {
        x: 0,
        opacity: 1
    }
};