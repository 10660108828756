export const tools = [
  {
    "title": "JavaScript",
    "related": [
      "React",
      "TypeScript",
      "Node.js",
      "Mocha",
      "Chai",
      "jQuery"
    ],
    "color": [
      "#ffb75e",
      "#ed8f03"
    ]
  },
  {
    "title": "Python",
    "related": [
      "Flask",
      "pygame"
    ],
    "color": [
      "#4B8BBE",
      "#FFE873"
    ]
  },
  {
    "title": "PHP",
    "related": [
      "Laravel",
      "WordPress",
      "SilverStripe"
    ],
    "color": [
      "#ed4264",
      "#ffedbc"
    ]
  },
  {
    "title": "Virtual Machines",
    "related": [
      "Docker",
      "Vagrant"
    ],
    "color": [
      "#00c9ff",
      "#92fe9d"
    ]
  },
  {
    "title": "Tooling",
    "related": [
      "webpack",
      "grunt",
      "npm",
      "yarn"
    ],
    "color": [
      "#1d78c1",
      "#8dd6f9"
    ]
  },
  {
    "title": "Server",
    "related": [
      "Linux",
      "Apache/Nginx",
      "SSL"
    ],
    "color": [
      "#673ab7",
      "#512da8"
    ]
  },
  {
    "title": "Bash",
    "related": [
      "scripting",
      "automation"
    ],
    "color": [
      "#114357",
      "#f29492"
    ]
  },
  {
    "title": "REGEX",
    "related": [],
    "color": [
      "#d1913c",
      "#ffd194"
    ]
  },
  {
    "title": "Command Line",
    "related": [
      "vim",
      "etc"
    ],
    "color": [
      "#0f9b0f",
      "#000000"
    ]
  },
  {
    "title": "Git",
    "related": [
      "version control"
    ],
    "color": [
      "#fd746c",
      "#ff9068"
    ]
  },
  {
    "title": "DNS",
    "related": [
      "configuration",
      "management"
    ],
    "color": [
      "#76b852",
      "#8dc26f"
    ]
  },
  {
    "title": "CSS",
    "related": [
      "SASS",
      "LESS"
    ],
    "color": [
      "#ff0084",
      "#33001b"
    ]
  },
  {
    "title": "HTML",
    "related": [],
    "color": [
      "#2980b9",
      "#2c3e50"
    ]
  },
  {
    "title": "Other",
    "related": [
      "There's more",
      "Always learning"
    ],
    "color": [
      "#8e9eab",
      "#eef2f3"
    ]
  }
];