import React from 'react';

export default function projectTemplate(data) {
    return (
        <div className={'project-template content'}>
            <h1>{ data.title }</h1>
            {/* Content Handling */}
            { data.content.map(((content) => {
                if (typeof content === 'object') {

                    // Image handling
                    if (content.type === 'image') {
                        if (Array.isArray(content.url)) {
                            return <div className='img-gallery'>{content.url.map(url => <div className='img-gallery-item'><img src={'/projects' + url} alt=""/></div>)}</div>
                        }

                        return <img src={'/projects' + content.url} alt=""/>
                    }

                    // Code handling
                    if (content.type === 'code') {
                        return <pre>{ content.code }</pre>
                    }

                    // PDF handling
                    if (content.type === 'pdf') {
                        return <iframe src={"https://drive.google.com/viewerng/viewer?embedded=true&url=https://hop.nz/projects" + content.url} width="100%" height="600" frameBorder="0"></iframe>
                    }

                    // Heading handling
                    if (content.type === 'h2') {
                        return <h2>{ content.content }</h2>
                    }
                    if (content.type === 'h3') {
                        return <h3>{ content.content }</h3>
                    }
                    if (content.type === 'h4') {
                        return <h4>{ content.content }</h4>
                    }
                }

                // Legacy string handling
                return <p>{ content }</p>
            }))}
            <p></p>
            {data.link &&
                <a href={data.link} target="_blank" className="project-open" rel="noopener noreferrer">View</a>
            }
        </div>
    )
}